import React, { useState, useEffect } from 'react';
import { Polyline, OverlayView } from '@react-google-maps/api';

const DrawMileLine = ({ startPoint, miles }) => {
  const [endPoint, setEndPoint] = useState(null);

  useEffect(() => {
    // Başlangıç noktasından belirli bir mil cinsinden uzaklıkta yeni bir nokta hesaplayın
    const calculateEndPoint = (startPoint, miles) => {
      const earthRadius = 3963; // Dünya yarıçapı (mil cinsinden)
      const bearing = Math.random() * 360; // Rastgele bir yön belirleyin
      const lat1 = startPoint.lat;
      const lon1 = startPoint.lng;

      const lat1Rad = lat1 * (Math.PI / 180);
      const lon1Rad = lon1 * (Math.PI / 180);
      const angularDistance = miles / earthRadius;

      let lat2Rad = Math.asin(Math.sin(lat1Rad) * Math.cos(angularDistance) +
        Math.cos(lat1Rad) * Math.sin(angularDistance) * Math.cos(bearing));
      let lon2Rad = lon1Rad + Math.atan2(Math.sin(bearing) * Math.sin(angularDistance) * Math.cos(lat1Rad),
        Math.cos(angularDistance) - Math.sin(lat1Rad) * Math.sin(lat2Rad));

      // Sonuçları derece cinsine çevirin
      lat2Rad = lat2Rad * (180 / Math.PI);
      lon2Rad = lon2Rad * (180 / Math.PI);

      return { lat: lat2Rad, lng: lon2Rad };
    };

    const newEndPoint = calculateEndPoint(startPoint, miles);
    setEndPoint(newEndPoint);
  }, [startPoint, miles]);

  const pathCoordinates = [startPoint, endPoint];

  const overlayViewContainerStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <>
      {/* Çizgiyi çizin */}
      <Polyline
        path={pathCoordinates}
        options={{
          strokeColor: 'blue',
          strokeOpacity: 0.7,
          strokeWeight: 3,
        }}
      />

      {/* Mesafe etiketini ekleyin */}
      {endPoint && (
        <OverlayView
          position={startPoint}
          mapPaneName={OverlayView.OVERLAY_LAYER}
          getPixelPositionOffset={(width, height) => ({
            x: -(width / 2),
            y: -(height / 2),
          })}
        >
          <div style={overlayViewContainerStyle}>
            <div style={{ fontSize: 15, background: 'rgba(255, 255, 255, 0.5)' , marginBottom: 100, padding: 10,width:'100%',whiteSpace:'nowrap',zIndex:2,borderRadius:'10px' }}>
              Distance: {miles} miles
            </div>
          </div>
        </OverlayView>
      )}
    </>
  );
};

export default DrawMileLine;
