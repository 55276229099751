import React from 'react'
import { Marker } from "@react-google-maps/api";

export default function ShipMarker({ destination}) {
    return <Marker position={destination}
      icon={{
        url: "icons/vesselVoyage.svg", // Özel marker resmi
        scaledSize: new window.google.maps.Size(40, 40), // Marker boyutu
        // origin: new window.google.maps.Point(0, 0), // Resmin başlangıç noktası
        anchor: new window.google.maps.Point(5, 20),
      }} />;
  }
