import React, { useEffect, useState } from 'react';
import { Polyline } from '@react-google-maps/api';

const AnimatedPolyline = ({ path }) => {
  const [animatedPath, setAnimatedPath] = useState([]);
  const numSteps = 250;
  const timePerStep = 5;

  useEffect(() => {
    let step = 0;
    const interval = setInterval(() => {
      step += 1;
      if (step > numSteps) {
        clearInterval(interval);
      } else {
        const areWeThereYet = window.google.maps.geometry.spherical.interpolate(
          path[0],
          path[1],
          step / numSteps
        );
        setAnimatedPath(prevPath => [...prevPath, areWeThereYet]);
      }
    }, timePerStep);

    return () => clearInterval(interval);
  }, [path, numSteps, timePerStep]);

  return (
    <Polyline
      path={animatedPath}
      options={{
        strokeColor: '#2ecc71',
        strokeOpacity: 1,
        strokeWeight: 0.75,
        strokeDasharray: '5 5',
        fillColor: '#2ecc71',
        fillOpacity: 0.35,
        geodesic: true,
      }}
    />
  );
};

export default AnimatedPolyline;
