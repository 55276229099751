
export const mapContainer = {
    width: '100vw',
    height: '100vh'
};

export const mapCenter = {
    lat: -3.745,
    lng: -38.523
};

export const mapZoom = 3;