import React, { useRef, useState, useEffect } from 'react';
import { DirectionsRenderer } from '@react-google-maps/api';
import jsonData from '../sample/RouteLegsMapping.json'

const DrawDirections = () => {
  const directionsServiceRef = useRef();
  const directionsRendererRef = useRef();
  const [directionsResponses, setDirectionsResponses] = useState([]);
  const [currentRouteIndex, setCurrentRouteIndex] = useState(0);

  useEffect(() => {
   
      directionsServiceRef.current = new window.google.maps.DirectionsService();
      directionsRendererRef.current = new window.google.maps.DirectionsRenderer();

      const calculateRoute = (startLocation, endLocation) => {
        if (!startLocation || !endLocation) {
          return;
        }

        const request = {
          origin: startLocation,
          destination: endLocation,
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsServiceRef.current.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponses((prevResponses) => [...prevResponses, result]);
          }
        });
      };
    console.log(jsonData)
      // JSON verilerini döngüye alarak rotaları hesapla
      jsonData.forEach((data) => {
        console.log(data.From_WPT__r.Name)
        const startLocation = data.From_WPT__r.Name;
        const endLocation = data.To_WPT__r.Name;
        calculateRoute(startLocation, endLocation);
      });
    
  }, [jsonData]);

  return (
      <>
          {directionsResponses.map((response, index) => (
            <DirectionsRenderer
              key={index}
              options={{
                directions: response,
                polylineOptions: {
                  icons: [
                    {
                      icon: {
                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, // Yön simgesi
                        scale: 3, // Boyut
                        strokeColor: 'red', // Renk
                      },
                      offset: '100%', // Simgenin konumu
                      repeat: '100px', // Tekrar
                    },
                  ],
                },
                markerOptions: {
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE, // Başlangıç/Bitiş simgesi
                    // url: "icons/vesselVoyage.svg", // Özel marker resmi
                    scale: 8, // Boyut
                    fillColor: '#3E7AB9', // Renk
                    fillOpacity: 1, // Opaklık
                    strokeWeight: 1, // Kenarlık kalınlığı
                  },
                },
              }}
              panel={null}
              routeIndex={index === currentRouteIndex ? 0 : -1}
            />
          ))}
      </>
    
  );
};

export default DrawDirections;
