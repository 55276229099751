import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// NOTE:https://www.ultimateakash.com/blog-details/Ii1TPGAKYAo=/How-To-Integrate-Google-Maps-Drawing-Manager-in-React-2023
// NOTE:https://dev.to/aws-builders/creating-a-serverless-api-using-aws-lambda-and-nodejs-with-typescript-and-expressjs-4kfk
