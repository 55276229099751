import React from 'react';
import { Polygon } from '@react-google-maps/api';


export default function DrawDonut({center,radiusInMilesFirst,radiusInMilesSecond}) {
    
      const drawCircle = (point, radius, dir) => {
        const d2r = Math.PI / 180; // degrees to radians
        const r2d = 180 / Math.PI; // radians to degrees
        const earthRadius = 3963; // Earth radius in miles
    
        const points = 32;
        const extp = [];
    
        const rlat = (radius / earthRadius) * r2d;
        const rlng = rlat / Math.cos(point.lat() * d2r);
    
        let start = 0;
        let end = points + 1;
        if (dir === -1) {
          start = points + 1;
          end = 0;
        }
    
        for (let i = start; dir === -1 ? i > end : i < end; i += dir) {
          const theta = Math.PI * (i / (points / 2));
          const ey = point.lng() + rlng * Math.cos(theta);
          const ex = point.lat() + rlat * Math.sin(theta);
          extp.push(new window.google.maps.LatLng(ex, ey));
        }
    
        return extp;
      };
    
      const generateCirclePolygon = (center, radiusInMilesFirst,radiusInMilesSecond) => {
        const circlePath = [
          ...drawCircle(
            new window.google.maps.LatLng(center.lat, center.lng),
            radiusInMilesFirst,
            1
          ),
          ...drawCircle(
            new window.google.maps.LatLng(center.lat, center.lng),
            radiusInMilesSecond,
            -1
          ),
        ];
    
        return (
          <Polygon
            paths={circlePath}
            options={{
              fillColor: 'red',
              fillOpacity: 0.5,
              strokeColor: 'blue',
              strokeOpacity: 0.5,
              strokeWeight: 1,
              clickable: false,
              draggable: false,
              editable: false,
              geodesic: false,
              zIndex: 1,
            }}
          />
        );
      };
  return (
    <>
    {generateCirclePolygon(center,radiusInMilesFirst,radiusInMilesSecond)}
    </>
  )
}

