import React, { useState, useRef } from 'react';
import { Button, Form, InputNumber, Tooltip, FloatButton } from 'antd';
import { DownCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Autocomplete } from '@react-google-maps/api';

const onFinish = (values) => {
    console.log('Success:', values);
};

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

export default function CreateRadialZoneForm({ donatData }) {
    const autocompleteRef = useRef();
    const [selectedCoordinates, setSelectedCoordinates] = useState(null);
    const [open, setOpen] = useState(false);
    const [startDistance, setStartDistance] = useState(0.1);
    const [radialDistance, setRadialDistance] = useState(0.2);

    const setSelectedPlace = (geometry) => {
        if (geometry && geometry.location) {
            const lat = geometry.location.lat();
            const lng = geometry.location.lng();

            setSelectedCoordinates({
                lat,
                lng,
            });
            //   console.log('Koordinat bilgisi: ', lat, lng);
        } else {
            console.error('Koordinat bilgisi bulunamadı.');
        }
    };
    const onPlaceChanged = async () => {
        const place = await autocompleteRef.current.getPlace();
        place?.geometry && setSelectedPlace(place.geometry);
    };
    const onLoadAutocomplete = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    }
    const autocompleteStyle = {
        boxSizing: 'border-box',
        border: '1px solid transparent',
        width: '90%',
        height: '35px',
        padding: '0 12px',
        borderRadius: '3px',
        boxShadow: '0 2px 6px rgba(13, 10, 253, 0.3)',
        outline: 'none',
        textOverflow: 'ellipses',
        marginBottom: '5px',
    };
    const handleCreateZone = () => {
        // Gerekli hesaplamaları yapabilir ve sonucu onCreateZone fonksiyonuna iletebilirsiniz.
        const zoneData = {
            selectedCoordinates,
            startDistance,
            radialDistance,
        };
        // console.log("zoneData", zoneData);
        // onCreateZone(zoneData);
        donatData(zoneData);
        setSelectedCoordinates(null);
        setOpen(false);
    };
    const btnForm = (
        <Form
            name="basic"
            style={{
                width: 350,
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '10px',
            }}

            initialValues={{
                startDistance, // Starting Location için başlangıç değeri
                radialDistance // Start Distance ve Radial Distance için başlangıç değeri
            }}

            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Starting Location:"
                name="startingLocation"
            >
                <Autocomplete
                    onLoad={onLoadAutocomplete}
                    onPlaceChanged={onPlaceChanged}
                >
                    <input
                        type='text'
                        placeholder='Search Location'
                        style={autocompleteStyle}
                    />

                </Autocomplete>
                <span style={{fontSize:11,color:'GrayText'}} >
                    {
                        selectedCoordinates && (selectedCoordinates.lat || selectedCoordinates.lng)
                            ?
                            `Selected Lat: ${selectedCoordinates.lat} | Lng: ${selectedCoordinates.lng}`
                            :
                            "Please select a location from the Location Input."

                    }
                </span>
            </Form.Item>

            <Form.Item
                label="Start Distance:"
                name="startDistance"
                value={startDistance}
                onChange={(e) => setStartDistance(e.target.value)}
                rules={[
                    {
                        required: true,
                        message: 'Please input your Start Distance (in miles)!',
                    },
                ]}
                style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                    marginRight: '8px',
                  }}
            >
                <InputNumber addonAfter="Miles" />
            </Form.Item>

            <Form.Item
                label="Radial Distance:"
                name="radialDistance"
                value={radialDistance}
                onChange={(e) => setRadialDistance(e.target.value)}
                rules={[
                    {
                        required: true,
                        message: 'Please input your Radial Distance (in miles)!',
                    },
                ]}
                style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                  }}
            >
                <InputNumber addonAfter="Miles" />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Tooltip title="Apply Coord">
                    <Button onClick={handleCreateZone} type="primary" icon={<CheckOutlined style={{ fontSize: '16px' }} />} >Apply & Draw Donut</Button>
                </Tooltip>
            </Form.Item>
        </Form>
    );
    return (
        <FloatButton.Group
            tooltip="Draw Donut"
            trigger="click"
            open={open}
            type="primary"
            onClick={() => setOpen(!open)}
            style={{left: '15px',top: '120px'}}
            icon={<DownCircleOutlined />}>
            {btnForm}
        </FloatButton.Group>
    );
}
