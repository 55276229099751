import React, { useState } from "react";
import { ComposableMap, Geographies, Geography, Annotation } from "react-simple-maps";
import worldCountries from '../sample/world-countries.json';

const App = () => {
    const [geographyData] = useState(worldCountries);

    return (
        <div>
            <ComposableMap>
                <Geographies geography={geographyData} fill="#D6D6DA" stroke="#FFFFFF" strokeWidth={0.5}>
                    {({ geographies }) =>
                        geographies.map((geo) => (
                            <Geography key={geo.rsmKey} geography={geo} />
                        ))
                    }
                </Geographies>
                <Annotation
                    subject={[29.3522, 48.8566]}
                    dx={-90}
                    dy={-30}
                    connectorProps={{
                        stroke: "#FF5533",
                        strokeWidth: 3,
                        strokeLinecap: "round"
                    }}
                >
                    <text x="-8" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                        {"Paris"}
                    </text>
                </Annotation>
            </ComposableMap>
        </div>
    );
};

export default App;