import React from 'react'
import { InfoWindow, Marker } from "@react-google-maps/api";

export default function CircleMarker({ destination, infoWindowContent }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMarkerClick = () => { setIsOpen(true); };
  const handleInfoWindowClose = () => { setIsOpen(false); };

  return (
    <Marker
      position={destination}
      onClick={handleMarkerClick} // Marker'a tıklama olayını ekle
      icon={{
        url: "icons/circle-selected-circle.svg",
        scaledSize: new window.google.maps.Size(20, 20),
        anchor: new window.google.maps.Point(10, 10),
      }}
    >
      {isOpen && ( // isOpen durumuna göre InfoWindow'u göster
        <InfoWindow onCloseClick={handleInfoWindowClose}>
          <div>{infoWindowContent}</div>
        </InfoWindow>
      )}
    </Marker>
  );
}

