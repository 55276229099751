
import axios from 'axios';

const getPolygonsByZoneId = async (zoneId) => {
  try {
    // console.log('REACT_APP_BACKEND_API_URL', process.env.REACT_APP_BACKEND_API_URL);
    const response = await axios.get( process.env.REACT_APP_BACKEND_API_URL + '/GEOZoneRsr/' + zoneId);
    // console.log('response', response, response.data);
    return response.data;
  } catch (error) {
    console.error('HATA Error fetching data:', error);
    throw error;
  }
};


export default getPolygonsByZoneId;
