import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GoogleMapsComp from './components/GoogleMapsComp';
import GetPolygonComp from './components/GetPolygonComp';
import RoutesComp from './components/geoLanes/RoutesComp';
import SimpleMap from './components/SimpleMaps';
import ActiveSailings from './components/ActiveSailings';

export default function App() {
  // console.log("GoogleApiKey",process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GoogleMapsComp />} />
        <Route path="/:zoneId" element={<GetPolygonComp />} />
        <Route path="/geoLanes" element={<RoutesComp />} />
        <Route path="/simpleMap" element={<SimpleMap />} />
        <Route path="/activeSailings" element={<ActiveSailings />} />
      </Routes>
    </Router>
  );
}