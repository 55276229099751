import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import getPolygonsByZoneId from '../utils/getPolygonsByZoneId';
import { Button, Space, message, Typography, Input, Col, Row } from 'antd';
import { LoginOutlined, CloudUploadOutlined, PoweroffOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { TextArea } = Input;

const GetPolygonComp = () => {
  const { zoneId } = useParams();
  const [polygons, setPolygons] = useState([]);
  const [map, setMap] = useState(null);
  const [mapPolygon, setMapPolygon] = useState(null);
  const [isEditable, setIsEditable] = useState(false); // editable durumu
  const [isDraggable, setIsDraggable] = useState(false); // draggable durumu
  const [description, setDescription] = useState('-'); // desc durumu
  const [center, setCenter] = useState(null);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
  const [initialPolygon, setInitialPolygon] = useState(null);
  
  const usePolygonChangeDetection = (mapPolygon, setUploadButtonDisabled) => {
    useEffect(() => {
      if (mapPolygon) {
        const path = mapPolygon.getPath();
  
        const pathListener = path.addListener('set_at', () => {
          handlePolygonChange();
        });
  
        const insertListener = path.addListener('insert_at', () => {
          handlePolygonChange();
        });
  
        return () => {
          pathListener.remove();
          insertListener.remove();
        };
      }
    }, [mapPolygon]);
  
    const handlePolygonChange = () => {
      setUploadButtonDisabled(false);
      // console.log('Polygon coordinates have changed.');
    };
  };
  usePolygonChangeDetection(mapPolygon, setUploadButtonDisabled); // Use the imported utility
  

  useEffect(() => {
    if(description !== initialPolygon?.description){
      setUploadButtonDisabled(false);
    }
    else{
      setUploadButtonDisabled(true);
    }
  }, [description]);

  const toggleEditable = () => {
    if (mapPolygon) {
      // mevcut duruma göre tersini yap
      const currentEditable = mapPolygon.getEditable();
      mapPolygon.setEditable(!currentEditable);
      setIsEditable(!currentEditable); // durumu güncelle
    }
  };

  const toggleDraggable = () => {
    if (mapPolygon) {
      // mevcut duruma göre tersini yap
      const currentDraggable = mapPolygon.getDraggable();
      mapPolygon.setDraggable(!currentDraggable);
      setIsDraggable(!currentDraggable); // durumu güncelle
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPolygonsByZoneId(zoneId);
        // console.log('data', data);
        if (data) {
          setPolygons(data[0]?.polygons || []);
          setIsEditable(false)
          setDescription(data[0]?.description || '-')
          setInitialPolygon({ polygons: data[0]?.polygons || [] , description: data[0]?.description || '-'})

          message.success('New Polygon has been drawn successfully!');
        }
      } catch (error) {
        console.error("Failed to retrieve data:", error);
        message.error('Failed to retrieve data!');
      }
    };

    fetchData();
  }, [zoneId]);

  useEffect(() => {
    // Her polygons değiştiğinde, haritadaki çokgenleri güncelle
    if (mapPolygon) {
      mapPolygon.setMap(null); // Önceki çokgeni haritadan kaldır
    }
    if (map && polygons.length > 0) {
      // Harita yüklü ve polygons varsa işlemleri yap
      if (polygons.length > 0) {
        const newMapPolygon = new window.google.maps.Polygon({
          paths: polygons[0]?.wpts.map(({ lat, lng }) => ({ lat, lng })),
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          editable: isEditable,
          draggable: isDraggable,
        });

        setMapPolygon(newMapPolygon); // Yeni çokgeni ayarla
        newMapPolygon.setMap(map); // Yeni çokgeni haritaya ekle
        computePolygonCenter();
        // console.log('computePolygonCenter', center);

      }
    }
  }, [polygons]);

  const mapStyles = {
    height: '100vh',
    width: '100%'
  };
  const logPolygonCoordinates = () => {
    if (mapPolygon) {
      const coordinates = mapPolygon.getPath().getArray().map((coord) => {
        return { lat: coord.lat(), lng: coord.lng() };
      });
      console.log('Polygon Coordinates:', coordinates);
    }
  };

  const computePolygonCenter = async () => {
    if (mapPolygon) {
      const bounds = new window.google.maps.LatLngBounds();
      mapPolygon.getPath().forEach((point) => bounds.extend(point));
      var center = await bounds.getCenter();
      // console.log('center', center.lat(), center.lng());
      setCenter({ lat: center.lat(), lng: center.lng() })
    }

  };
  
  return (
    <div>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={['geometry']}
      >
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={4}
          center={{ lat: 41.8781, lng: -87.6298 }} // Merkez koordinatları
          onLoad={(map) => setMap(map)}
          draggable={false}
        >
          <Space direction="vertical" style={{ position: "absolute", padding: "0 5px 5px 5px", right: 10, border: "0.5px solid #0F4C81", borderTop: 0, borderBottomLeftRadius: 50, borderBottomRightRadius: 5 }} >
            <Title level={5} style={{ background: "rgba(255,255,255,0.6)", transition: "background 0.5s ease", padding: "0 5px", margin: 2.5, borderBottomLeftRadius: 10, borderBottomRightRadius: 5 }} >
              Zone Id: {zoneId}
              {!isEditable && <><br />Desc: {description}</>}</Title>
            {polygons.length > 0 &&
              <>
                <Row>
                  <Col span={12} className="gutter-row">
                    <Button icon={isEditable ? <PoweroffOutlined /> : <LoginOutlined />} onClick={toggleEditable} shape="round" type='primary'>
                      {isEditable ? 'Disable Edit' : 'Enable Edit'}
                    </Button>
                  </Col>
                  <Col span={12} className="gutter-row">
                    <Button icon={isDraggable ? <PoweroffOutlined /> : <LoginOutlined />} onClick={toggleDraggable} shape="round" type='primary' style={{ float: 'right' }}>
                      {isDraggable ? 'Disable Drag' : 'Enable Drag'}
                    </Button>
                  </Col>
                </Row>
                {isEditable &&
                  <Row gutter={6}>
                    <Col className="gutter-row" span={24}>
                      <TextArea
                        style={{ backgroundColor: "#fff" }}
                        disabled={!isEditable} rows={2} placeholder="Enter desc..." value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Col>
                  </Row>}

                <Row gutter={6}>
                  <Col className="gutter-row" span={24}>
                    <Button
                      icon={<CloudUploadOutlined />}
                      onClick={logPolygonCoordinates}
                      disabled={uploadButtonDisabled}
                      shape="round" type='primary' style={{ float: 'right' }}>
                      Upload new Plygon
                    </Button>
                  </Col>
                </Row>
              </>
            }
          </Space>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default GetPolygonComp;