import React, { useRef, useState, useEffect } from 'react';
import { Autocomplete, DrawingManager, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';

import CreateRadialZoneForm from './CreateRadialZoneForm';
import DrawApothem from './DrawApothem';
import DrawDonut from './DrawDonut';
import DrawDirections from './DrawDirections'
import { defaultCenter, containerStyle, polygonOptions, autocompleteStyle, deleteIconStyle, triangle, rectangle } from './GoogleMapsCompDefaults'
import { FloatButton, Button } from 'antd';
import { SendOutlined, ExpandOutlined } from '@ant-design/icons';
import SOUTHERNPLAINS_GULF_ZONE from '../sample/SOUTHERNPLAINS_GULF_ZONE.json'
import TEXAS_US_ZONE from '../sample/TEXAS_US_ZONE.json'


const libraries = ['places', 'drawing'];
const MapComponent = () => {

    const [donatData, setDonatData] = useState(null);
    const mapRef = useRef();
    const polygonRefs = useRef([]);
    const activePolygonIndex = useRef();
    const autocompleteRef = useRef();
    const drawingManagerRef = useRef();
    const [isDirection, setIsDirection] = useState(false);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries
    });


    //Sample Plygons
    const [polygons, setPolygons] = useState([
        // triangle,   // triangle
        // rectangle,   // rectangle
    ]);

    const [center, setCenter] = useState(defaultCenter);

    const drawingManagerOptions = {
        polygonOptions: polygonOptions,
        drawingControl: true,
        drawingControlOptions: {
            position: window.google?.maps?.ControlPosition?.TOP_CENTER,
            drawingModes: [
                window.google?.maps?.drawing?.OverlayType?.POLYGON
            ]
        }
    }

    const onLoadMap = (map) => {
        mapRef.current = map;
    }

    const onLoadPolygon = (polygon, index) => {
        polygonRefs.current[index] = polygon;
    }

    const onClickPolygon = (index) => {
        activePolygonIndex.current = index;
    }

    const onLoadAutocomplete = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    }
    const onPlaceChanged = async () => {
        const place = await autocompleteRef.current.getPlace();
        const geometry = place?.geometry;
        if (geometry) {
            const bounds = new window.google.maps.LatLngBounds();
            if (geometry.viewport) {
                bounds.union(geometry.viewport);
            } else {
                bounds.extend(geometry.location);
            }
            mapRef.current.fitBounds(bounds);
        }
    }

    const onLoadDrawingManager = drawingManager => {
        drawingManagerRef.current = drawingManager;
    }

    const onOverlayComplete = ($overlayEvent) => {
        drawingManagerRef.current.setDrawingMode(null);
        if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
            const newPolygon = $overlayEvent.overlay.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))

            // start and end point should be same for valid geojson
            const startPoint = newPolygon[0];
            newPolygon.push(startPoint);
            $overlayEvent.overlay?.setMap(null);
            setPolygons([...polygons, newPolygon]);
        }
    }

    const onDeleteDrawing = () => {
        const filtered = polygons.filter((polygon, index) => index !== activePolygonIndex.current)
        setPolygons(filtered)
    }

    const onEditPolygon = (index) => {
        const polygonRef = polygonRefs.current[index];
        if (polygonRef) {
            const coordinates = polygonRef.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

            const allPolygons = [...polygons];
            allPolygons[index] = coordinates;
            setPolygons(allPolygons)
        }
    }

    const getDirections = () => {
        const filtered = polygons.filter((polygon, index) => index !== activePolygonIndex.current)
        setPolygons(filtered)
    }

    const drawBoundary = () => {
        console.log("Clicked to drawBoundary btn")
        // Extracting the polygon from the JSON array
        const extractedPolygon01 = SOUTHERNPLAINS_GULF_ZONE[0].polygons[0].wpts.map(point => ({ lat: point.lat, lng: point.lng }));
        const extractedPolygon02 = TEXAS_US_ZONE[0].polygons[0].wpts.map(point => ({ lat: point.lat, lng: point.lng }));

        // Name for the polygon (you can use any field from your JSON data)
        const polygonName = "S PLAINS & GULF"; // Change this to the desired name

        // Assuming 'triangle' and 'rectangle' are existing polygon shapes in the state
        // Adding the extracted polygon to the existing 'polygons' state
        setPolygons([...polygons, extractedPolygon01, extractedPolygon02]);

    }


    return (
        isLoaded
            ?
            <div className='map-container' style={{ position: 'relative' }}>
                {
                    drawingManagerRef.current
                    &&
                    <div
                        onClick={onDeleteDrawing}
                        title='Delete shape'
                        style={deleteIconStyle}>
                    </div>
                }
                <GoogleMap
                    zoom={12}
                    center={center}
                    onLoad={onLoadMap}
                    mapContainerStyle={containerStyle}
                    onTilesLoaded={() => setCenter(null)}
                >
                    <DrawingManager
                        onLoad={onLoadDrawingManager}
                        onOverlayComplete={onOverlayComplete}
                        options={drawingManagerOptions}
                    />
                    {
                        polygons.map((iterator, index) => (
                            <Polygon
                                key={index}
                                onLoad={(event) => onLoadPolygon(event, index)}
                                onMouseDown={() => onClickPolygon(index)}
                                onMouseUp={() => onEditPolygon(index)}
                                onDragEnd={() => onEditPolygon(index)}
                                options={polygonOptions}
                                paths={iterator}
                                draggable
                                editable
                            />
                        ))
                    }
                    <Autocomplete
                        onLoad={onLoadAutocomplete}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <input
                            type='text'
                            placeholder='Search Location'
                            style={autocompleteStyle}
                        />
                    </Autocomplete>

                    <CreateRadialZoneForm donatData={setDonatData} />
                    <FloatButton style={{
                        top: 70, left: 15
                    }}
                        tooltip="Get Route"
                        onClick={() => setIsDirection(!isDirection)}
                        icon={<SendOutlined />}
                    />
                    {
                        donatData &&
                        <>
                            <DrawDonut center={donatData.selectedCoordinates} radiusInMilesFirst={donatData.startDistance} radiusInMilesSecond={donatData.radialDistance} />
                            <DrawApothem startPoint={donatData.selectedCoordinates} miles={donatData.startDistance} />
                        </>
                    }
                    {isLoaded && isDirection && <DrawDirections />}
                    <Button type="primary" style={{ top: 10, left: "15%" }} icon={<ExpandOutlined />}
                        onClick={drawBoundary}
                    >
                        Draw Boundary
                    </Button>
                </GoogleMap>
            </div>
            :
            null
    );
}

export default MapComponent; 