import React, { useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import transfers from '../../sample/AG_2023_Lanes.json';
import { message } from 'antd';
import { mapOptions, mapStyles, defaultCenter } from './RoutesCompFuncs';
import CircleMarker from './CircleMarker';
import AnimatedPolyline from './AnimatedPolyline';

const RoutesComp = () => {
  const [mapLoaded, setMapLoaded] = useState(false);

  const onLoad = () => {checkIfPageIsLoaded();};

  const checkIfPageIsLoaded = () => {
    const mapElement = document.getElementById('geo-lanes-map');
    if (mapElement && mapElement.childNodes.length > 0) {
      setMapLoaded(true);
    } else {
      setTimeout(checkIfPageIsLoaded, 100);
    }
  };

  const areCoordinatesValid = (coords) => {
    return !isNaN(coords.lat) && !isNaN(coords.lng);
  };

  const renderRoutes = () => {
    if (!mapLoaded) return null;

    return transfers.map((transfer, index) => {
      const data = transfer?.Lane?.split(',');
      const origin = { lat: parseFloat(data[2]), lng: parseFloat(data[3]) };
      const destination = { lat: parseFloat(data[6]), lng: parseFloat(data[7]) };

      const originValid = areCoordinatesValid(origin);
      const destinationValid = areCoordinatesValid(destination);

      if (!originValid || !destinationValid) {
        showErrorMsg();
      }

      return (
        <React.Fragment key={index}>
          {originValid &&  <CircleMarker destination={origin} />}
          {destinationValid && <CircleMarker destination={destination} />}
          {originValid && destinationValid && (
            <AnimatedPolyline path={[origin, destination]} />
          )}
        </React.Fragment>
      );

      function showErrorMsg() {
        const invalidCoordinate = !originValid ? 'Origin' : 'Destination';
        const originName = data[0];
        const destinationName = data[3];

        message.warning({
          content: (
            <>
              Line {index + 1}: {invalidCoordinate} coordinates are invalid.
              <br />Origin Name: <b>{originName}</b>
              <br />Destination Name: <b>{destinationName}</b>
            </>
          ),
          duration: 5,
        });
      }
    });
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      onLoad={onLoad}
      libraries={['geometry']}
    >
      <GoogleMap
        id="geo-lanes-map"
        mapContainerStyle={mapStyles}
        zoom={3}
        center={defaultCenter}
        options={{ styles: mapOptions.styles }}
      >
        {mapLoaded && renderRoutes()}
      </GoogleMap>
    </LoadScript>
  );
};

export default RoutesComp;
