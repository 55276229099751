export const mapStyles = {
  height: "100vh",
  width: "100%"
};

export const defaultCenter = {
  lat: 22.39600,
  lng: -97.9370
};

export const mapOptions = {
  styles: [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        { saturation: -30 }, // Daha az doygunluk
        { lightness: 10 }, // Daha az ışık
        { hue: '#0055ff' }, // Mavi tonlarına yakın bir renk
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#001f5e' }], // Daha koyu mavi tonu
    },
    // Diğer stilleri burada ekleyebilirsiniz
  ],
};

export const getRandomColor = () => {
  // Rastgele renk oluşturma fonksiyonu
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const originCountryColors = {};
export const assignRandomColorToOriginCountry = (country) => {
  if (!originCountryColors[country]) {
    originCountryColors[country] = getRandomColor();
  }
  return originCountryColors[country];
};


