import React from 'react'
import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import SailingGeoMAP from '../sample/SailingGeoMAP.json';
import CircleMarker from './geoLanes/CircleMarker';
import ShipMarker from './geoLanes/ShipMarker';
import { mapContainer, mapCenter, mapZoom } from '../utils/genericMapConfig';

const extractRoutes = (voyagesData) => {
    const routes = voyagesData.voyages.map((voyage) => {
        const routeEvents = voyage.routeEvents.map((event) => ({
            lat: event.lat,
            lng: event.lng,
        }));
        return routeEvents;
    });
    return routes;
};

function ActiveSailingCircleMarker({ port }) {
    return (
      <CircleMarker
        destination={port}
        infoWindowContent={
          <div style={{ textAlign: "center" }}>
            <b>{port.city}</b><br />
            <span>{port.countryISO2}</span>
          </div>
        }
      />
    );
  }

function ActiveSailings() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const routes = extractRoutes(SailingGeoMAP);
    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        routes.forEach(route => {
            route.forEach(coord => bounds.extend(coord));
        });
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);
    // Define a symbol using SVG path notation, with an opacity of 1.
    const lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={mapContainer}
            center={mapCenter}
            zoom={mapZoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <ActiveSailingCircleMarker port={SailingGeoMAP.voyages[0].originPort} />
            {isLoaded &&
                routes.map((route, index) => (
                    <Polyline
                        key={index}
                        path={route}
                        options={{
                            strokeOpacity: 0,
                            icons: [
                                {
                                    icon: lineSymbol,
                                    offset: "0",
                                    repeat: "20px",
                                },
                            ],
                        }}
                    />
                ))}
            <ShipMarker destination={SailingGeoMAP.voyages[0].routeEvents.slice(-1)[0]} />
            <ActiveSailingCircleMarker port={SailingGeoMAP.voyages[0].destinationPort} />
        </GoogleMap>
    ) : <></>;
}

export default React.memo(ActiveSailings);
