import deleteIcon from '../assets/images/remove.png';

//Map Styles
export const containerStyle = {
  width: '100vw',
  height: '100vh',
}

export const autocompleteStyle = {
  boxSizing: 'border-box',
  border: '1px solid transparent',
  width: '240px',
  height: '38px',
  padding: '0 12px',
  borderRadius: '3px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
  fontSize: '14px',
  outline: 'none',
  textOverflow: 'ellipses',
  position: 'absolute',
  right: '8%',
  top: '11px',
  marginLeft: '-120px',
}

export const deleteIconStyle = {
  cursor: 'pointer',
  backgroundImage: `url(${deleteIcon})`,
  height: '24px',
  width: '24px',
  marginTop: '5px', 
  backgroundColor: '#fff',
  position: 'absolute',
  top: "2px",
  left: "52%",
  zIndex: 99999
}

export const polygonOptions = {
  fillOpacity: 0.3,
  fillColor: '#ff0000',
  strokeColor: '#ff0000',
  strokeWeight: 2,
  draggable: true,
  editable: true
}
//Sample Locations
export const triangle = [
  { lat: 40.755610, lng: -73.925242 },
  { lat: 40.760610, lng: -73.935242 },
  { lat: 40.755610, lng: -73.945242 }
];

export const rectangle = [
  { lat: 40.750610, lng: -73.955242 },
  { lat: 40.745610, lng: -73.955242 },
  { lat: 40.745610, lng: -73.945242 },
  { lat: 40.750610, lng: -73.945242 },
  { lat: 40.750610, lng: -73.955242 }
];

export const defaultCenter = {
  lat: 40.7128, // New York's (latitude)
  lng: -74.0060, // New York's (longitude)
};